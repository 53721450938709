<template>
  <div class="notification">
    <div ref="myDiv" class="notification-btn" @click="toggle">
      <i
        v-if="hasUnReadNotifications"
        class="bi bi-circle-fill has-pending-notification"
      />
      <button
        v-tooltip.bottom="'Notifications'"
        class="btn-icon"
        aria-label="Notification"
      >
        <i class="bi bi-bell" />
      </button>
    </div>
    <Dialog
      id="notificationModal"
      v-model:visible="showNotificationDialog"
      :pt="dialogPassThroughOptions"
      modal
      :closable="false"
      :dismissable-mask="true"
      @hide="updateNotifProduct = null"
    >
      <div v-if="notifications.length === 0" class="no-notifications">
        No notifications.
      </div>
      <ul v-else class="notification-list px-0 mb-0">
        <li
          v-for="notification in notifications"
          :key="notification.id"
          :class="[
            notification.highlight ? 'notification-list__highlight' : '',
          ]"
        >
          <a @click="notificationClickHandler(notification)">
            <div class="notification-item">
              <span class="status-container">
                <i v-if="!notification.read" class="bi bi-circle-fill unread" />
              </span>
              <span class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                  <h4 class="mb-0">{{ notification.title }}</h4>
                  <p class="m-0 p-0 pt-2">{{ notification.desc }}</p>
                </div>
              </span>
              <h5 class="mb-0 mt-1">
                {{ notificationDate(notification) }}
              </h5>
            </div>
          </a>
        </li>
      </ul>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import type { INotification, IProduct } from '~/stores/types/app'

  // Store
  const appStore = useAppStore()

  // Properties
  const showNotificationDialog = ref(false)
  const updateNotifProduct: Ref<IProduct | null> = ref(null)

  // Emitter composbles
  const emitter = useEmitter()

  // Primevue component pass through options
  const dialogPassThroughOptions = {
    mask: {
      class: 'notification-modal-mask',
    },
  }

  // Computed properties
  const notifications = computed(() => {
    if (updateNotifProduct.value) {
      const allNotifications = useCloneDeep(appStore.notifications)
      const filteredItems = allNotifications.filter((item) =>
        updateNotifProduct.value?.notif_status?.notifications?.includes(
          item.id,
        ),
      )
      const remainingItems = allNotifications.filter(
        (item) =>
          !updateNotifProduct.value?.notif_status?.notifications.includes(
            item.id,
          ),
      )
      // Group all the notification related to the product but highlight only the update notifications and not the new ones.
      filteredItems.forEach(
        (item) => (item.highlight = item.category === 'update'),
      )
      return filteredItems.concat(remainingItems)
    }
    return appStore.notifications
  })

  const hasUnReadNotifications = computed(() => {
    return appStore.notifications?.some((notification) => !notification.read)
  })

  // Hooks
  onMounted(() => {
    return emitter.on('openNotificationDialog', (product: IProduct) => {
      showNotificationDialog.value = true
      updateNotifProduct.value = Object.assign({}, product)
      setTimeout(async () => {
        await appStore.updateNotificationStatus(
          product.notif_status.notifications,
        )
        appStore.updateProductNotifStatus(product)
      }, 500)
    })
  })

  // Methods / Event handlers
  const toggle = () => {
    showNotificationDialog.value = true
  }

  const notificationClickHandler = (notification: INotification) => {
    if (!notification.read) {
      const product = appStore.rawProductsData.find(
        (prdItem) =>
          prdItem.slug === notification.product ||
          prdItem.id === notification.id,
      )
      appStore.updateNotificationStatus([notification.id]).then(() => {
        // To update the product card icons
        appStore.updateProductNotifStatus(product, notification.id)
      })
    }
    if (!notification.link) return
    if (HelperMethods.isDifferentDomain(notification.link)) {
      window.open(notification.link, '_blank')
    } else {
      const url = new URL(notification.link)
      const routePath = url.pathname + url.hash
      navigateTo(routePath)
      // To set the active route path to highlight the item in side nav bar
      emitter.emit('setActiveRoutePath')
    }
    showNotificationDialog.value = false
  }

  const notificationDate = (notification: INotification) => {
    return HelperMethods.getNotificationDateDiff(notification.created)
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_color-palette.scss';
  .notification {
    .notification-btn {
      position: relative;
      .has-pending-notification {
        position: absolute;
        top: 10px;
        font-size: 0.65rem;
        right: 12px;
        color: $red;
        cursor: pointer;
      }
    }
  }

  .no-notifications {
    min-width: 275px;
    font-size: 16px;
    font-weight: 500;
    color: $compile-blue-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 2rem;
    margin-inline: 1rem;
  }

  .notification-list {
    width: 450px !important;
    max-height: 60vh;
    overflow-y: auto;
    right: 0px;
    background-color: #fff !important;
    min-width: 275px;
    border-radius: 0.5rem;
    &__highlight {
      background-color: #c7ceff;
    }
    a {
      text-decoration: none;
    }
    li {
      list-style: none;
      @include font-style(16px, 500, $compile-blue-dark);
      padding: 1.25rem;
      cursor: pointer;
      border-bottom: 1px solid #00000035;
      &:hover {
        background-color: $compile-hover-blue-light;
      }
    }
    .notification-item {
      display: flex;
      white-space: pre-wrap;
      word-break: break-word;
      & .status-container {
        width: 1.5rem;
        margin-top: -4px;
      }
      & .unread {
        color: $red;
        font-size: 0.65rem;
      }

      span {
        width: 100%;
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: $compile-blue-dark;
          line-height: 1.2;
        }
      }
      h5 {
        margin-left: 2rem;
        white-space: nowrap;
        color: $compile-blue-dark;
        font-size: 12px;
        margin-top: -2px;
        font-weight: 500;
        opacity: 0.7;
      }
      p {
        font-size: 12px;
        color: $compile-blue-dark;
        opacity: 0.7;
        font-weight: 500;
      }
    }
  }
</style>
